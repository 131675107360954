import { get } from 'lodash'
import {
	SAVE_LIVE_AGENT_SESSION,
	CLEAR_LIVE_AGENT_SESSION,
	SAVE_FINESSE_SESSION,
	CLEAR_FINESSE_SESSION,
	SAVE_CALL250_SESSION,
	CLEAR_CALL250_SESSION
} from '../types/externalIntegrations'
import { EXTERNAL_INTEGRATIONS } from '../utils/enums'

const clearQueryParams = () => {
	const url = new URL(window.location.href)
	url.searchParams.delete(EXTERNAL_INTEGRATIONS.ID_QUERY)
	url.searchParams.delete(EXTERNAL_INTEGRATIONS.SOURCE_QUERY)
	window.history.pushState({}, '', url.toString())
}

export const createLiveAgentSession = (liveAgentId) => {
	return {
		type: SAVE_LIVE_AGENT_SESSION,
		payload: {
			liveAgentId
		}
	}
}

export const clearLiveAgentSession = () => (dispatch) => {
	clearQueryParams()
	dispatch({ type: CLEAR_LIVE_AGENT_SESSION })
}

export const createFinesseSession = (finesseId) => {
	return {
		type: SAVE_FINESSE_SESSION,
		payload: {
			finesseId
		}
	}
}

export const clearFinesseSession = () => (dispatch) => {
	clearQueryParams()
	dispatch({ type: CLEAR_FINESSE_SESSION })
}

export const createCall250Session = (call250Id) => {
	return {
		type: SAVE_CALL250_SESSION,
		payload: {
			call250Id
		}
	}
}

export const clearCall250Session = () => (dispatch) => {
	clearQueryParams()
	dispatch({ type: CLEAR_CALL250_SESSION })
}

export const clearAllExternalSessions = () => (dispatch) => {
	dispatch(clearLiveAgentSession())
	dispatch(clearFinesseSession())
	dispatch(clearCall250Session())
}

export const loadExternalSessionFromUrl = () => (dispatch) => {
	const urlParams = new URLSearchParams(window.location.search)
	const externalId = urlParams.get(EXTERNAL_INTEGRATIONS.ID_QUERY)
	const source = urlParams.get(EXTERNAL_INTEGRATIONS.SOURCE_QUERY)

	if (source === EXTERNAL_INTEGRATIONS.LIVE_AGENT && typeof externalId === 'string') {
		const trimmedExternalId = externalId.substring(0, 50)
		dispatch(createLiveAgentSession(trimmedExternalId))
	}

	if (source === EXTERNAL_INTEGRATIONS.FINESSE && typeof externalId === 'string') {
		const trimmedExternalId = externalId.substring(0, 50)
		dispatch(createFinesseSession(trimmedExternalId))
	}

	if (source === EXTERNAL_INTEGRATIONS.CALL250 && typeof externalId === 'string') {
		const trimmedExternalId = externalId.substring(0, 50)
		dispatch(createCall250Session(trimmedExternalId))
	}
}

export const loadExternalSessionFromInterakcia = (interakcia) => (dispatch, getStore) => {
	const externeIDsInInterakcia = get(interakcia, 'externeIDs', [])

	const store = getStore()

	externeIDsInInterakcia.forEach((externeID) => {
		const { externalId, source } = externeID

		if (source === EXTERNAL_INTEGRATIONS.LIVE_AGENT && typeof externalId === 'string' && !get(store, 'externalIntegrations.liveAgentId')) {
			dispatch(createLiveAgentSession(externalId))
		}

		if (source === EXTERNAL_INTEGRATIONS.FINESSE && typeof externalId === 'string' && !get(store, 'externalIntegrations.finesseId')) {
			dispatch(createFinesseSession(externalId))
		}

		if (source === EXTERNAL_INTEGRATIONS.CALL250 && typeof externalId === 'string' && !get(store, 'externalIntegrations.call250Id')) {
			dispatch(createCall250Session(externalId))
		}
	})
}
