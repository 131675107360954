import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { Route, Redirect, Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { get, isEmpty, map, find, isInteger } from 'lodash'
import { withTranslation } from 'react-i18next'
import NavigationPrompt from 'react-router-navigation-prompt'
import Select from '../../atoms/BasicSelect'

// utils
import { filterAvailableBusinessChannels, isLoggedIn } from '../../utils/auth'
import { history } from '../../utils/history'
import { FILTER_SELECTORS } from '../../utils/enums'
import { INDEX } from '../../utils/routes'
import { getIsMop } from '../../utils/obchodnyPartner'

// actions
import * as AuthActions from '../../actions/AuthActions'
import * as UkonyActions from '../../actions/UkonyActions'
import * as DataActions from '../../actions/DataActions'
import InterakcieActions from '../../actions/Interakcie'

// elements
import ElementLoading from '../ElementLoading'
import ElementFailure from '../ElementFailure'
import DefaultModal from '../Modals/DefaultModal'

// components
import { CHANNEL_TO_CHANGE_SELECTOR } from '../BusinessChannelDropdownMenu'
import LeavePageModal from '../Modals/LeavePageModal'

class AuthRequiredRoute extends Route {
	static propTypes = {
		withoutPartner: PropTypes.bool,
		needPartner: PropTypes.bool,
		component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
		location: PropTypes.shape({
			pathname: PropTypes.string.isRequired
		}).isRequired,
		layout: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
		page: PropTypes.string,
		authActions: PropTypes.shape({
			heartbeat: PropTypes.func.isRequired
		}).isRequired,
		t: PropTypes.func.isRequired
	}

	state = {
		heartbeatInterval: null,
		choosenBiznisKanal: null
	}

	static defaultProps = {
		needPartner: false,
		withoutPartner: false
	}

	handleKeyDown = (e) => {
		if (e.keyCode == 116) {
			// allow f5
			sessionStorage.setItem('f5', true)
			this.removeEvents()
		}
	}

	keepOnPage = (e) => {
		const message = 'Upozornenie!\n\nNaozaj chcete odísť zo stránky?'
		e.returnValue = message
		return message
	}

	confirmModal = () => {
		const { dataActions } = this.props

		dataActions.unregisterLeavePageModal()
		// clear previous session storage data
		sessionStorage.clear()
		// reload current url
		location.reload()
	}

	notConfirmModal = () => {
		const { dataActions } = this.props

		dataActions.unregisterLeavePageModal()
		// go back on previews page
		history.goBack()
	}

	handleNewInteraction = () => {
		const { interakcia, obchodnyPartnerDetail, interakcieActions, needPartner } = this.props

		if (needPartner && (!interakcia.data || !obchodnyPartnerDetail.data)) {
			const split = get(location, 'pathname', '').split('/')
			const partnerId = parseInt(get(split, '[2]'))
			if (isInteger(partnerId)) {
				interakcieActions.zaciatokInterakcie(partnerId)
			} else {
				history.push(`${INDEX}?clear=true`)
			}
		}
	}

	componentDidMount() {
		if (isLoggedIn()) {
			const { auth, authActions } = this.props

			const heartbeatInterval = setInterval(authActions.heartbeat, 1000 * 60 * 1) // every one minute
			this.setState({ heartbeatInterval })

			if (get(auth, 'businessChannel.actual')) {
				this.handleNewInteraction()
			}
		}
	}

	componentWillUnmount() {
		if (this.state.heartbeatInterval) {
			clearInterval(this.state.heartbeatInterval)
		}
	}

	componentDidUpdate = async () => {
		const { obchodnyPartnerDetail } = this.props

		if (isEmpty(obchodnyPartnerDetail.data)) {
			document.title = `ZSE Care`
			return
		}

		const isMop = getIsMop(get(obchodnyPartnerDetail, 'data.skupinaOpravneni'))

		if (isMop) {
			document.title = `${get(obchodnyPartnerDetail, 'data.obchodneMeno', '')} | ZSE Care`
		} else {
			document.title = `${get(obchodnyPartnerDetail, 'data.meno', '')} ${get(obchodnyPartnerDetail, 'data.priezvisko', '')} | ZSE Care`
		}
	}

	confirmSelectBusinessChannelModal = () => {
		const { ciselniky, authActions } = this.props
		const { choosenBiznisKanal } = this.state

		const biznisKanal = find(get(ciselniky, 'data.kanal', []), {
			id: choosenBiznisKanal
		})
		authActions.setDefaultBusinessChannel([
			{
				...biznisKanal,
				default: true
			}
		])
		this.handleNewInteraction()
	}

	selectBusinessChannelChanged = (value) => {
		this.setState({
			choosenBiznisKanal: value
		})
	}

	render = () => {
		const {
			auth,
			ciselniky,
			interakcia,
			computedMatch,
			t,
			channelToSwitch,
			leavePageModal,
			page,
			needPartner,
			obchodnyPartnerDetail,
			component: Component,
			layout: Layout
		} = this.props
		const { choosenBiznisKanal } = this.state

		if (!isLoggedIn()) {
			localStorage.setItem('initUrl', window.location.pathname + window.location.search)
			return <Redirect to='/prihlasenie' />
		}

		if (interakcia.isLoading || obchodnyPartnerDetail.isLoading || ciselniky.isLoading) {
			return <ElementLoading />
		}

		if (interakcia.isFailure || obchodnyPartnerDetail.isFailure || ciselniky.isFailure) {
			return (
				<div className='not-found-page'>
					<ElementFailure size='lg' text={t('components:AuthRequiredRoute.Nastala neočakávaná chyba')} />
					<div className='content'>
						<Link to={`${INDEX}?clear=true`} className='button' data-color='blue'>
							{' '}
							Späť na vyhľadávanie
						</Link>
					</div>
				</div>
			)
		}

		if (!get(auth, 'businessChannel.actual')) {
			// remove D2D kanal
			const filtered = filterAvailableBusinessChannels(get(ciselniky, 'data.kanal', []))
			// build select options from ciselniky data
			const biznisKanalyOptions = map(filtered, (kanal) => ({ value: kanal.id, label: kanal.popis }))
			const biznisKanalValue = find(biznisKanalyOptions, (kanal) => kanal.value == choosenBiznisKanal) || ''

			const biznisKanalySelect = (
				<Select
					value={biznisKanalValue}
					classNamePrefix='react-select'
					placeholder={t('components:AuthRequiredRoute.Zvoľte biznis kanál')}
					name='biznisKanaly'
					options={biznisKanalyOptions}
					onChange={(el) => this.selectBusinessChannelChanged(el.value)}
				/>
			)
			return (
				<DefaultModal
					modalTitle={t('components:AuthRequiredRoute.Zvoľte si prednastavený kanál pre prihlasovanie')}
					modalContent={biznisKanalySelect}
					leftButton={{
						onClick: this.confirmSelectBusinessChannelModal,
						text: t('components:Uložiť'),
						color: 'green',
						disabled: !choosenBiznisKanal
					}}
					visible={true}
				/>
			)
		}

		let modal = null
		let registerModal = null
		if (get(interakcia, 'data.opCislo') && computedMatch.params.cisloOP !== get(interakcia, 'data.opCislo')) {
			return (
				<DefaultModal
					modalTitle={t('components:Upozornenie')}
					modalContent={t(
						'components:AuthRequiredRoute.Chystáte sa opustiť otvorenú interakciu nad obchodným partnerom Táto akcia zahodí všetky neuložené zmeny Prajete si pokračovať a opustiť obchodného partnera?'
					)}
					leftButton={{
						onClick: this.confirmModal,
						text: t('components:Áno'),
						color: 'green'
					}}
					rightButton={{
						onClick: this.notConfirmModal,
						text: t('components:Nie'),
						color: 'red',
						outline: true
					}}
					visible
				/>
			)
		}

		registerModal = (
			<NavigationPrompt when={get(leavePageModal, 'show') && !channelToSwitch}>
				{({ onConfirm, onCancel }) => {
					return <>{get(leavePageModal, 'show') && !channelToSwitch && <LeavePageModal t={t} onConfirm={onConfirm} onCancel={onCancel} />}</>
				}}
			</NavigationPrompt>
		)

		if (needPartner && (!interakcia.data || !obchodnyPartnerDetail.data)) {
			return <ElementLoading />
		}

		if (Layout) {
			return (
				<>
					{modal}
					{registerModal}
					<Layout {...this.props} data-page={page}>
						<Component {...this.props} />
					</Layout>
				</>
			)
		}

		return (
			<>
				{modal}
				{registerModal}
				<Component {...this.props} />
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	obchodnyPartnerDetail: state.obchodnyPartner.detail,
	interakcia: state.interakcie.detail,
	channelToSwitch: state.data[CHANNEL_TO_CHANGE_SELECTOR],
	leavePageModal: state.data.leavePageModal,
	ciselniky: state.ciselniky,
	selectedFiltersUkony: get(state, `selectedFilters.${FILTER_SELECTORS.SIDEBAR_HISTORY}`, {})
})

const mapDispatchToProps = (dispatch) => ({
	interakcieActions: bindActionCreators(InterakcieActions, dispatch),
	authActions: bindActionCreators(AuthActions, dispatch),
	ukonyActions: bindActionCreators(UkonyActions, dispatch),
	dataActions: bindActionCreators(DataActions, dispatch)
})

export default compose(withTranslation('components'), connect(mapStateToProps, mapDispatchToProps))(AuthRequiredRoute)
