import { find, get, toUpper } from 'lodash'
import { INTERAKCIE_START_LOAD_FAIL, INTERAKCIE_START_LOAD_DONE, INTERAKCIE_START_LOAD_START } from '../../types/interakcie'
import { getReq, postReq } from '../../utils/request'
import { history } from '../../utils/history'
import { loadObchodnyPartnerDetail } from '../ObchodniPartneri/ObchodnyPartnerDetail'
import { loadProcesnyKonfigurator } from '../ProcesnyKonfigurator/ProcesnyKonfiguratorActions'
import { statusPop } from '../StatusActions'
import { loadCiselniky } from '../CiselnikyActions'
import { resetStorage } from '../ResetStorageActions'
import { loadExternalSessionFromInterakcia } from '../ExternalIntegrationsActions'
import { INDEX } from '../../utils/routes'
import { getExterneIDs } from '../../utils/externalIntegrations'

export function interakcieStartLoadStart() {
	return {
		type: INTERAKCIE_START_LOAD_START
	}
}

export function interakcieStartLoadDone(interakcia) {
	return {
		type: INTERAKCIE_START_LOAD_DONE,
		payload: {
			interakcia
		}
	}
}

export function interakcieStartLoadFail() {
	return {
		type: INTERAKCIE_START_LOAD_FAIL
	}
}

export const refreshAcw = () => {
	return async (dispatch, getStore) => {
		const store = getStore()
		const { data } = store.interakcie.detail

		assignAcw(data, dispatch)
	}
}

export async function assignAcw(interaction, dispatch) {
	const response = await getReq(`/api/v2/interakcie/${interaction.id}`)
	const acw = get(response, 'response.content.acw', [])
	const riesitelIdentita = get(interaction, 'riesitelIdentita') || get(response, 'response.content.riesitelIdentita') || null

	const fullInteraction = {
		...interaction,
		acw,
		riesitelIdentita,
		pinStav: toUpper(get(response, 'response.content.pinStav', 'NEPLATNY')),
		pinPlatnostDo: get(response, 'response.content.pinPlatnostDo')
	}

	dispatch(interakcieStartLoadDone(fullInteraction))
}

export const zaciatokInterakcie = (opCislo) => {
	return async (dispatch, getStore) => {
		const store = getStore()
		const { user, businessChannel } = store.auth
		const { isLoading, data } = store.obchodnyPartner.detail
		if (isLoading || data) {
			return
		}
		dispatch(resetStorage())
		dispatch(interakcieStartLoadStart())
		dispatch(loadProcesnyKonfigurator())
		dispatch(loadCiselniky())
		try {
			// CP-2838 - First check for permissions and load OP detail
			await dispatch(loadObchodnyPartnerDetail(opCislo))

			const externeIDs = getExterneIDs(get(store, 'externalIntegrations'))

			// get all non complete interaction by opCislo
			const query = { opCislo, ukoncenaOd: '', triedenie: 'vytvorenaOd:DESC' }
			const checkCurrentOpenInterakcie = await getReq(`/api/v0/interakcie`, query)

			// get active user's interaction
			const currentOpenInterakcie = find(get(checkCurrentOpenInterakcie, 'response.obsah', []), { riesitel: get(user, 'id') })
			if (currentOpenInterakcie) {
				// dispatch last open interaction
				const lastInteraction = currentOpenInterakcie
				assignAcw(lastInteraction, dispatch)
				// CP-3082 - Z existujucej interakcie sa nacitaju externeIDs (ak ziadne nemame ulozene v sessionStorage)
				dispatch(loadExternalSessionFromInterakcia(currentOpenInterakcie))
				// clear previous messages from search page
				dispatch(statusPop(store.statuses.statuses.length))
			} else {
				// dispatch new interaction

				const body = {
					opCislo: `${opCislo}`,
					smer: 'INBOUND',
					kanal: get(businessChannel, 'actual'),
					riesitel: user.id,
					externeIDs
				}
				const newInterakcia = await postReq(`/api/v0/interakcie`, {}, body)

				assignAcw(newInterakcia.response.obsah, dispatch)
				// CP-3082 - Z existujucej interakcie sa nacitaju externeIDs (ak ziadne nemame ulozene v sessionStorage)
				dispatch(loadExternalSessionFromInterakcia(newInterakcia.response.obsah))
				// clear previous messages from search page
				dispatch(statusPop(store.statuses.statuses.length))
			}
		} catch (e) {
			dispatch(interakcieStartLoadFail())
			history.push(`${INDEX}?clear=true`)
		}
	}
}
